.loading-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0);
    z-index: 999;
    backdrop-filter: blur(2px);
}
.circle{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-top: 4px solid rgb(56,152,236);
    padding: 10px;
    opacity: 1;
    animation: rotate-1 infinite;
    animation-duration: 1s;

}
.circle-2{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-bottom: 3px solid white;
    
    opacity: 1;
    animation: rotate-2 infinite;
    animation-duration: 1s;

}
@keyframes rotate-1 {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
@keyframes rotate-2 {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}