.reservation-user-main-container{
    width: 100%;
    padding-bottom: 50px;
}
.reservation-user-container{
    width: 95%;
    max-width: 960px;
    padding: 15px;
    margin: 0 auto;
    
    /*background-color: #181717;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);*/
}
.reservation-user-radio{
}
.reservation-user-radio input{
    margin: 10px;
    width: 17px;
    height: 17px;
    accent-color: black;
}
.reservation-user-radio span{
    color: rgb(88,88,88);
}
.reservation-user-form{
    display: flex;
    flex-direction: column;
}
.reservation-user-input{
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    color: rgb(88,88,88);
    max-width: 500px;
    background-color: white;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.reservation-user-input:focus{
    border: 1px solid rgb(84, 44, 85);
    border-radius: 0;
    outline: none;
}
.reservation-user-input:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
@keyframes inputHover {
    from {transform: translateX(0px);}
    to {transform: translateX(10px);}
}
.reservation-user-button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.reservation-user-button:hover{
    background-color: black;
}
.reservation-user-button:disabled{
    opacity: 0.5;
}
.reservation-product-primary-text{
    color: rgb(88,88,88);
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
}
.reservation-user-form span{
    font-size: 20px;
    margin: 5px;
    margin-left: 15px;
}
.reservation-user-form div{
    width: 100%;
    max-width: 500px;
}
.reservation-user-form div input{
    width: 85%;
}
@keyframes rotate-1 {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
.error-message{
    margin-left: 5px;
    font-size: small;
    color: red;
}

.reservation-user-participatns{
    color: rgb(88,88,88);
}
.reservation-user-participatns-checkbox{
    margin: 5px;
    margin-right: 10px;
    padding: 0px;
    max-width: 20px;
}
.reservation-user-participatns .reservation-user-input{
    width: 100%;
} 