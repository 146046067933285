.reservation-main-container{
    padding-top: 300px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding-bottom: 50px;
}
.reservation-main-admin-container{
}
.reservation-container{
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 20px;
  background-color: red;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/12345.png');
  box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
.reservation-admin-container{
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 20px;
}
.reservation-container-right{
  width: 100%;
  max-width: 300px;
}
@media screen and (max-width: 991px) {
  .reservation-main-container{
      padding-top: 60px;
      padding-bottom: 0px;
  }
  
  .reservation-container-right{
    width: 100%;
    max-width: none;
  }
}
/*.reservation-main-container p{
    color: #fff;
    text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #0fa,
      0 0 82px #0fa,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;
    font-size: 100px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}*/
