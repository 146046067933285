.create-product-container{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.create-product-container textarea {
    width: 100%;
    max-width: 800px;
    min-height: 150px;
}
.create-product-main-components-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.productType{
    margin-left: 15px;
}
