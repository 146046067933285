.admin-product-card-container{
    width: 95%;
    border: 1px solid black;
    margin: 10px auto;
    padding: 10px;
}
.admin-product-card-images{
    display: flex;
    width: 100%;
    max-width: 400px;
}
.admin-product-card-image{
    width: 100%;
    background-size: cover;
    background-position: 100% 50%;
    height: 300px;
}
.admin-product-card-container button{
    min-width: 100px;
    margin-right: 20px;
}
.admin-product-card-container input{
    margin-top: 10px;
}
.admin-product-card-container textarea{
    margin-top: 10px;
    width: 100%;
    min-height: 150px;
}
.admin-product-card-times{
    display: flex;
    overflow-y: scroll;
    padding-bottom: 10px;
}
.admin-product-card-time{
    width: 80px;
    text-align: center;

}
.checkbox-time{
    font-size: 12px;
    margin-left: 5px;
    margin-top: -3px;
}
.appointment-modal{
    
}
.appointment-div-modal{
    width: 95%;
    max-width: 500px;
    background-color: white;
}
.appointment-div-modal button{
    width: 60px;
    margin: 5px;
}
.selected-type-appointment{
    background-color: grey;
    color: white;
}
.appointmetn-days{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 20px;
}
.appointment-time{
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}
.appointment-time-input{
    width: 100px;
}