.admin-gallery-slider{
    width: 90%;
    max-width: 800px;
    margin: 10px auto;
}
.edit-gallery-buttons-container{
    display: flex;
    justify-content: center;
}
.edit-gallery-container{
    display: flex;
    justify-content: center;
}
.edit-gallery-container button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.edit-gallery-container button:hover{
    background-color: black;
}
.edit-gallery-container button:disabled{
    opacity: 0.8;
}
.edit-gallery-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}
.edit-gallery-buttons-container button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.edit-gallery-buttons-container button:hover{
    background-color: black;
}
.edit-gallery-buttons-container button:disabled{
    opacity: 0.8;
}
.edit-gallery-buttons-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}
.add-image-container{
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.delete-image-container{
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
}
.delete-images-container{
    width: 90%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
.image-to-delete{
    width: 150px;
    margin: 5px;
}
.selected-image-to-delete{
    width: 150px;
    margin: 5px;
    border: 4px solid rgb(56,152,236);
    border-radius: 3px;
}