.find-voucher-search{
    background-color: white;
    padding: 20px 10px;
    margin: 10px;
    color: rgb(150,150,150);
    border-radius: 5px;
}
.find-voucher-search input{
    border: 1px solid rgb(150,150,150);
    color: rgb(150,150,150);
    border-radius: 5px;
    outline: none;
}
.find-voucher-search button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    margin: 5px;
    color: white;
    border-radius: 5px;
}

.voucher-info-container-v2{
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
    color: rgb(150,150,150);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.voucher-info-container-v2 button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    margin: 5px;
    color: white;
    border-radius: 5px;
}
.find-voucher-legend-container{
    display: flex;
    flex-wrap: wrap;
}

.find-voucher-legend-container div{
    color: white;
    font-weight: 600;
    margin: 5px 10px 5px 0px;
    padding: 8px;
    border-radius: 5px;
}
.all-vouchers{
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
    .find-voucher-search input{
        width: 100px;
    }
    .voucher-info-container-v2{
        width: calc(100% - 10px);
    }
  }

.voucher-details-modal{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.voucher-details{
    width: calc(100% - 10px);
    max-width: 500px;
    height: 300px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    color: rgb(150,150,150);
}