.popup-container{
    display: flex;
    width: 300px;
    height: 80px;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    position: fixed;
    top: 100px;
    left: 100%;
    z-index: 10000;
}
.popup-icon-container{
    height: 100%;
    width: 54px;
    border-radius: 7px 0px 0px 7px;
    padding: 23px 10px;
}
.popup-message-container{
    padding: 5px 10px;
}
.popup-message-container div{
    font-size: 11px;
    margin-top: -5px;
}
.bell-icon{
    width: 34px;
    height: 34px;
    animation: bellAnimation forwards;
    animation-duration: 1.5s;
}
.popup-message-container h4{
    color: white !important;
}


.show-container{
    animation: show forwards;
    animation-duration: 3s;
}
@keyframes show {
    0% {transform: translateX(0px);}
    10% {transform: translateX(-310px);}
    90% {transform: translateX(-310px);}
    100% {transform: translateX(0px);}
}
