.reservation-overview-date-container{
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
    color: rgb(150,150,150);
}

.reservation-overview-container{
    display: flex;
    flex-wrap: wrap;
}
.reservation-overview-date-container-buttons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.reservations-by-date-scroll-container{
    overflow-x: scroll;
}

/* width */
.reservations-by-date-scroll-container::-webkit-scrollbar {
    height: 10px;
}

/* Track */
.reservations-by-date-scroll-container::-webkit-scrollbar-track {
    height: 10px;
}

/* Handle */
.reservations-by-date-scroll-container::-webkit-scrollbar-thumb {
    background: rgb(60, 75, 100);
    border-radius: 2px;
    width: 5px;
}

.reservations-by-date-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(235,237,239);
    padding: 10px;
    min-width: 1300px;
}

.reservations-by-date-container:nth-child(odd){
    background-color: rgb(240,240,240);
}

.reservations-by-date-container div{
    flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
}

.reservations-by-date-container div:nth-child(odd){
    flex-grow: 3;
  flex-shrink: 3;
  flex-basis: 100px;
}

.reservations-by-date-container div:nth-child(7){
    flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  display: flex;
  justify-content: end;
}

.reservations-by-date-container div:nth-child(5){
    flex-grow: 4;
  flex-shrink: 4;
  flex-basis: 100px;
}


.reservations-by-date-container button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    margin: 5px;
    color: white;
    border-radius: 5px;
}

.reservation-overview-date-container-buttons button{
    padding: 5px;
    margin: 10px;
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    color: white;
    border-radius: 5px;
}

.reservation-overview-search{
    background-color: white;
    padding: 20px 10px;
    margin: 10px;
    color: rgb(150,150,150);
    border-radius: 5px;
}
.reservation-overview-search input{
    border: 1px solid rgb(150,150,150);
    color: rgb(150,150,150);
    border-radius: 5px;
    outline: none;
}
.reservation-overview-search button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    color: white;
    border-radius: 5px;
}
.reservation-overview-search-items{
    display: flex;
    flex-wrap: wrap;
}

.reservation-overview-search-items div{
    margin: 10px 10px 10px 0px;
}

.receipt-container-v2{
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
    color: rgb(150,150,150);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-left: 4px solid rgb(0,123,255);
}

.receipt-container-v2 button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    margin: 5px;
    color: white;
    border-radius: 5px;
}

.receipt-container-v2-product-name{
    color: rgb(0,123,255);
}