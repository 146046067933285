.home-description-contaner{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}
.home-description-heading{
    text-align: center;
    color: white;
}
.home-description-paragraph{
    width: 70%;
    margin-top: 52px;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
}
@media screen and (max-width: 767px) {
    .home-description-paragraph{
        width: 95%;
        font-size: 4vw;
    }
}