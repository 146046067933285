
.create-vouchers-container-item{
    border-radius: 5px;
    color: rgb(150,150,150);
    background-color: white;
    padding: 10px;
    margin: 10px 10px;
}
.create-vouchers-container-item button{
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    margin: 5px;
    color: white;
    border-radius: 5px;
}
.notification-container{
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    color: white;
}