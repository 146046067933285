.home-hero-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 150px;
    align-items: center;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
}
@media screen and (max-width: 991px) {
    .home-hero-container{
        padding-top: 60px;
    }
}
.home-hero-title{
    margin-bottom: 50px;
    font-family: Oswald, sans-serif;
    color: #f4ff9e;
    font-size: 3vw;
    font-weight: 300;
    text-align: center;
    letter-spacing: 32px;
    padding-top: 5vw;
    padding-left: 32px;
}
.home-hero-text-span{
    font-weight: bold;
}
.home-hero-title-2{
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: Tahoma, Verdana, Segoe, sans-serif;
    color: #fff;
    font-size: 5vw;
    line-height: 65px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
}
.home-hero-heading-1{
    color: white;
    margin-top: 44px;
    text-align: center;
    font-size: 2.5vw;
    font-weight: bolder;
}
.home-hero-divider-yellow{
    width: 80%;
    border-top: 1px solid #f4ff9e;
    margin: 0 auto;
}
.home-hero-heading-2{
    color: white !important;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    text-align: center;
    font-weight: 100;
    font-size: 1.8vw;
}
.home-hero-button{
    font-size: 25px;
    padding: 15px 20px;
    border: 2px solid #f4ff9e;
    margin: 0 auto;
    background: transparent;
    color: #f4ff9e;
    border-radius: 5px;
    font-weight: bold;
}
.home-hero-button:hover{
    background-color: #f4ff9e;
    color: #444;
    border-radius: 5px;
}
.home-hero-text{
    font-family: Montserrat;
    color: #f0f0f0;
    font-size: 21px;
    padding-top: 100px;
    padding-bottom: 100px;
    font-weight: bolder;
    text-align: center;
}
@media screen and (max-width: 767px) {
    .home-hero-title{
        margin-bottom: 30px;
        padding-top: 80px;
        font-size: 5vw;
        letter-spacing: 13px;
    }
    .home-hero-title-2{
        font-size: 10vw;
    }
    .home-hero-heading-1{
        font-size: 5vw;
    }
    .home-hero-heading-2{
        font-size: 4vw;
    }
    .home-hero-button{
        font-size: 18px;
    }
    .home-hero-text{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}


