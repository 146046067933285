.calendar-main-container{
    display: flex;
    /*background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.64)), to(rgba(0, 0, 0, 0.64))), url('../../images/Home/escape-room-strategies-1080x675.jpeg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../images/Home/escape-room-strategies-1080x675.jpeg');
    background-size: cover;
    background-attachment: scroll, fixed;*/
    
    flex-direction: column;
    padding-bottom: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.calendar-container{
    width: 60%;
    max-width: 960px;
    background-color: rgb(100,100,100);
    margin: 20px auto;
    //padding: 0px 10px 10px 10px;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
@media only screen and (max-width: 600px) {
    .calendar-container{
        width: 95%;
    }
  }
.calendat-navbar{
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 10px;
    align-items: center;
    background-color: rgb(84, 44, 85);
}
.calendat-navbar-button{
    font-size: 30px;
    color: white;
    cursor: pointer;
}
.calendat-navbar-label{
    color:white;
    font-size: 20px;
    font-weight: bold;
}
.calendar-days{
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
}
.calendar-day{
    color: rgb(88,88,88);
    font-weight: bold;
    width: 50;
    text-align: center;
}
.calendar-items{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13%, 1fr));
    margin-top: 10;
    flex-wrap: wrap;
}
.calendar-item-used, .calendar-item-unused{
    width: 100%;
    max-width: 100;
    background-color: white;
    border: 1px solid white;
    padding-top: 1vw;
    padding-bottom: 1vw;
    text-align: center;
    font-size: 18px;
    box-sizing: border-box;
    color: rgb(88,88,88);
}
.current{
    background-color: rgb(240,240,240);
    border: 1px solid rgb(240,240,240);
}
.calendar-item-used:hover{
    border: 1px solid rgb(56,152,236);
    color: rgb(88,88,88);
    box-sizing: border-box;
}

.selected{
    border: 1px solid rgb(56,152,236);
    color: rgb(88,88,88);
    box-sizing: border-box;
}