
.details-main-container{
    padding-top: 300px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding-bottom: 50px;
}
.details-container{
    display: flex;
    max-width: 1200px;
    margin: 0px auto;
    padding: 20px;
    background-color: red;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/12345.png');
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
.details-image{
    width: 50%;
    height: auto;
    display: flex;
}
.details-image img{
    width: 100%;
    background-size: cover;
    background-position: 100% 50%;
    min-height: 300px;
}
.details{
    width: 50%;
    padding: 20px;
}
.details h1,h2,h3,h4,h5{
    color: rgb(84, 44, 85) !important;
    font-weight: bold !important;
}
.details button{
    min-height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
.details button:hover{
    background-color: black;
}

@media screen and (max-width: 991px) {
    .details-main-container{
        padding-top: 60px;
        padding-bottom: 0px;
    }
    .details-container{
        flex-direction: column;
    }
    .details-image{
        width: 100%;
    }
    .details{
        width: 100%;
    }
}