.contact-main-container{
    padding-top: 300px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding-bottom: 50px;
    
}
.contact-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/12345.png');
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
.contact-form-2{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.contact-form-input-3{
    width: 95%;
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    color: rgb(88,88,88);
    max-width: 700px;
    background-color: white;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input-3:focus{
    border: 1px solid rgb(84, 44, 85);
    border-radius: 0;
    outline: none;
}
.contact-form-input-3:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
.contact-form-input-4{
    width: 95%;
    height: 150px;
    margin: 5px;
    padding: 10px;
    border: 1px solid black;
    color: rgb(88,88,88);
    max-width: 700px;
    background-color: white;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input-4:focus{
    border: 1px solid rgb(84, 44, 85);
    border-radius: 0;
    outline: none;
}
.contact-form-input-4:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
.contact-form-button-2{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.contact-form-button-2:hover{
    background-color: black;
}
@media screen and (max-width: 991px) {
    .contact-main-container{
        padding-top: 60px;
        padding-bottom: 0px;
    }
}