.home-promotional-container{
    display: flex;
    background-color: black;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 70px;
    padding-right: 25px;
    padding-left: 25px;
}
.home-promotional-card{
    min-width: 300px;
    max-width: 500px;
    margin: 20px;
    padding: 30px;
    align-items: center;
    background-color: #181717;
    text-align: center;
}
.home-promotional-heading{
    backface-visibility: visible;
    color: #f4ff9e !important;
    text-align: center;
}