.home-theme-container{
    display: flex;
    background-color: rgb(95,47,71);
    color: white;
    padding: 50px;
    padding-bottom: 50px;
}
.home-theme-child{
    margin: 20px auto;
    font-size: 30px;
    font-weight: bold;
    padding: 40px;

}
.home-theme-child-2{
    background-color: rgb(49,38,44);
    padding: 40px;
    margin: 20px auto;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
.home-theme-child-2-heading{
    text-align: center;
    color: white !important;
}
@media screen and (max-width: 762px) {
    .home-theme-container{
        padding: 20px;
        flex-direction: column;
    }
    .home-theme-child{
        font-size: 20px;
        padding: 0px;
    }

}