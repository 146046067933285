$textColor: #f4ff9e;
.home-about-container{
    display: flex;
    flex-direction: column;
    background-color: black;
    align-items: center;
    color: textColor;
}
.home-about-heading{
    font-size: 35px;
    font-weight: bold;
    color: #f4ff9e;
    margin-top: 50px;
    padding: 10px;
    text-align: center;
}
.home-about-content{
    color: #f4ff9e;
    text-align: center;
    padding: 30px;
    max-width: 1200px;
}
@media screen and (max-width: 960px) {
    .home-about-heading{
        font-size: 25px;
    }
}