.vouchers-main-container{
    padding-top: 300px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding-bottom: 50px;
}
.vouchers-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/12345.png');
    //background-color: rgb(84, 44, 85);
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.vouchers-container-background{
    background-image:url('../../images/effect.png');
    background-size: 60% 100%;
    background-position: 90% 0%;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
}
.vouchers-container-right{
    margin: 50px 20px;
}
.amount-items{
    display: flex;
    align-items: center;
}
.vouchers-container h1,h2{
    color: rgb(84, 44, 85) !important;
    font-weight: bold !important;
}
.amount-item{
    color:rgb(84, 44, 85);
    width: 100%;
    max-width: 100px;
    background-color: white;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 10px;
    font-weight: bold;
    border: 2px solid rgb(84, 44, 85);
    cursor: pointer;
}
.selected-amount-item{
    width: 100%;
    max-width: 100px;
    background-color: rgb(84, 44, 85);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 10px;
    font-weight: bold;
    color: white;
    border: 2px solid rgb(84, 44, 85);
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    cursor: pointer;
}
.selected-amount-item h5{
    color: white !important;
}
@media screen and (max-width: 991px) {
    .vouchers-main-container{
        padding-top: 60px;
        padding-bottom: 0px;
    }
}