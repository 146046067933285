
.nav-logo-img{
    margin-top: 5px;
    width: 47px;
    height: 47px;
}
.nav-logo-text-xs-sm-md{
    margin-top: 5px;
    margin-left: 22px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: rgb(244, 255, 158);
    font-size: 15px;
    font-weight: 500;

}

/*header lg*/
.header-container-lg{
    overflow: hidden;
    position: fixed;
    transition: 0.3s;
    width: 100%;
    padding-top: 50px;
    background: transparent;
    border-bottom: none;
    top: 0;
    z-index: 999;
}
.container-lg-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}
.container-lg-top-item{
    padding: 0px;
    width: 30%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
    color: rgb(244, 255, 158);
    font-size: 18px;
    font-weight: bold;
}
.nav-logo-img-lg{
    margin-top: 10px;
    width: 60px;
    height: 60px;
}
.nav-logo-text-lg{
    margin-top: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: rgb(244, 255, 158);
    font-size: 27px;
    width: 100%;
    font-weight: 500;
    transition: 0.3s;
}
.container-lg-bottom{
    display: flex;
    justify-content: center;
    height: 40px;
}
.nav-option-lg{
    text-align: center;
    margin: 0px 20px;
    height: 60px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: white;
    font-size: 17px;
    text-decoration: none;
    padding-top: 10px;
}
.nav-option-lg:hover{
    color: rgb(244, 255, 158);
}
/*header lg*/
/*.header-container-lg{
    position: fixed;
    width: 100%;
    height: 60px;
    background-color: black;
    border-bottom: 1px solid rgb(244,255,158);
    z-index: 999;
}
.header-lg{
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    flex-direction: row;
    height: 60px;
    margin: 0 auto;
}
.nav-logo-lg{
    display: flex;
    align-items: center;
    max-width: 400px;
    height: 50px;
}
.nav-options-lg{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    height: 60px;
}
.nav-option-lg{
    color: rgb(244, 255, 158);
    text-decoration: none;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}*/

/*header -xs-sm-md*/
.language-options{
    display: flex;
    background-color: black;
    align-items: center;
    padding-left: 45px;
}
.header-container-xs-sm-md{
    position: fixed;
    width: 100%;
    min-height: 60px;
    background-color: black;
    border-bottom: 1px solid rgb(244,255,158);
    z-index: 999;
}
.header-xs-sm-md{
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    flex-direction: row;
    height: 60px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
}
.nav-logo-xs-sm-md{
    display: flex;
    align-items: center;
    max-width: 400px;
    height: 50px;
}
.nav-button-xs-sm-md{
    margin-top: 5px;
    width: 40px;
    height: 40px;
    background-color: black;
    border: none;
    font-size: 25px;
    color: white;
}
.menu-xs-sm-md{
    display: none;
    width: 100%;
    position: fixed;
    z-index: 100;
}
.nav-options-xs-sm-md{
    width: 100%;
    padding-top: 10px;
    padding-left: 50px;
    background-color: black;
    height: 40px;
    color: rgb(244, 255, 158);
    text-decoration: none;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}