
html{
    background-color: white;
    height: 100%;
}
.page-title{
    text-align: center;
}
/*html{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('./User/assets/images/bg1.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding: 0;
}
body{
    margin: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('./User/assets/images/bg1.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
}*/