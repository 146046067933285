.find-reservation-container{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.find-reservation-container button{
    min-height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.find-reservation-container button:hover{
    background-color: black;
}
.find-reservation-container button:disabled{
    opacity: 0.8;
}
.find-reservation-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}
.receipt-container{
    width: 100%;
    border: 1px solid black;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.pagination{
    display: flex;
}
.pagination-page{
    width: 30px;
    height: 30px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-link{
    color: black;
    text-decoration: none;
    background: transparent
}
.page-button{
    width: 30px;
    height: 30px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-button a{
    text-decoration: none;
    color: black;
}
.page-active{
    font-weight: bold;
}
.active-link{
    background: transparent;
}