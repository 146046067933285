.create-product-main-component-container{
    width: 300px;
    margin: 5px;
    border: 1px solid grey;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.create-product-main-component-container-selected{
    border: 1px solid black;
    background-color: rgb(220,220,220);
    width: 300px;
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.create-product-main-component-container:hover{
    transform: scale(1.01);
}
.create-product-main-component-container img{
    width: 100%;
}
.create-product-main-component-container-selected img{
    width: 100%;
}