.reservation-step-container{
    display: flex;
    padding: 10px;
}
.reservation-step{
    width: 100%;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    font-size: 2vw;
    font-weight: bold;
}
.reservation-step-child-1{
    color: black;
    background-color: #f4ff9e;
    border-radius: 15px 0px 15px 0px;
    
    border: 2px solid #f4ff9e;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
}
.reservation-step-child-2{
    color: rgb(84, 44, 85);
    padding-left: 10px;
    padding-right: 30px;
    padding-bottom: 5px;
    /*border: 2px solid #f4ff9e;
    border-left: none;
    border-radius: 0px 0px 15px 0px;*/
}
@media screen and (max-width: 762px) {
    .reservation-step{
        font-size: 7vw;
    }

}
@media screen and (max-width: 960px) and (min-width: 763px) {
    .reservation-step{
        font-size: 5vw;
    }
    /*.reservation-step{
        font-size: 3vw;
    }*/

}