.admin-options-main-container{
    display: flex;
    height: 100%;
    padding-top: 60px;

}
.admin-options-menu{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
}
.open-menu{
    width: 30px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 5px;
    padding-top: 20px;
    display: none;
}
.admin-options-menu-2{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
    position: fixed;
}
@media only screen and (max-width: 600px) {
    .admin-options-menu{
        display: none;
    }
    .open-menu{
        display: block;
    }
}
.admin-options-menu span{
    cursor: pointer;
    color: grey;
}
.admin-options-menu span:hover{
    color: black
}
.admin-options-menu-divider{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
}
.admin-options-container{
    margin: 0 auto;
    overflow-x: scroll;
    width: 100%;
}
.admin-options-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .admin-options-container::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .admin-options-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.admin-options-container table {
    margin: 0 auto;
}
.admin-options-container table tr {
    margin: 0 auto;
}
.admin-options-container table td{
    border: 1px solid black;
    max-width: 400px;
}

.admin-products-main-container button{

}
.admin-options-main-container button{
    height: 40px;
    width: 100px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.admin-options-main-container button:hover{
    background-color: black;
}
.admin-options-main-container button:disabled{
    opacity: 0.8;
}
.admin-options-main-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}


//admin accounts
.accounts-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.account-card-container{
    width: 250px;
    height: 350px;
    border: 1px solid rgb(220,220,220);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account-card-container-img{
    width: 150px;
    height: 150px;
    border: 1px solid rgb(220,220,220);
    margin: 20px;
    border-radius: 50%;
}


//create-account
.create-new-account-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}