.statistics-first-container{
    display: flex;
    flex-wrap: wrap;
}

.statistics-first-container div{
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    color: rgb(150,150,150);
    padding: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: large;
}

.statistics-first-container div:nth-child(1){
    border-left: 4px solid rgb(0,123,255);
}
.statistics-first-container div:nth-child(2){
    border-left: 4px solid rgb(255,193,8);
}
.statistics-first-container div:nth-child(3){
    border-left: 4px solid rgb(40,167,69);
}
.statistics-first-container div:nth-child(4){
    border-left: 4px solid rgb(220,53,69);
}

.statistics-second-container{
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
}
.statistics-second-container button{
    padding: 5px;
    margin: 10px;
    border: 1px solid rgb(60, 75, 100);
    background-color: rgb(60, 75, 100);
    color: white;
    border-radius: 5px;
}