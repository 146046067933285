.left-admin-panel-menu {
    height: 100%;
    width: 250px;
    background-color: rgb(48, 60, 84);
    color: rgb(200, 200, 200);
    position: absolute;
    transform-origin: left;
    transform: scaleX(1);
}

.open-menu-v2{
    animation: openMenu forwards;
    animation-duration: 0.5s;
    transform-origin: left;
}
.close-menu-v2{
    animation:closeMenu forwards;
    animation-duration: 0.5s;
    transform-origin: left;
}


.left-admin-panel-menu-logo {
    height: 60px;
    width: 250px;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.left-admin-panel-menu-2 {
    width: 250px;
    height: calc(100% - 120px);
    background-color: rgb(60, 75, 100);
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-admin-panel-menu-items {
    width: 240px;
    height: calc(100% - 30px);
    background-color: rgb(60, 75, 100);
    overflow-y: scroll;
}

/* width */
.left-admin-panel-menu-items::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.left-admin-panel-menu-items::-webkit-scrollbar-track {
    width: 5px;
}

/* Handle */
.left-admin-panel-menu-items::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
    border-radius: 2px;
    width: 5px;
}

.left-admin-panel-menu-caret {
    height: 60px;
    width: 240px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: large;
}

.left-admin-panel-menu-items div {
    margin: 15px 30px;
    cursor: pointer;
}

.left-admin-panel-menu-items div:hover {
    color: white;
}

.left-admin-panel-menu-items b {
    margin: 15px 15px;
}

.left-admin-panel-container {
    width: 100%;
    height: 100%;
    padding-left: 250px;
    padding-top: 60px;
    overflow-y: scroll;
    background-color: rgb(235, 237, 239);
    ;
}

/* width */
.left-admin-panel-container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.left-admin-panel-container::-webkit-scrollbar-track {
    width: 5px;
}

/* Handle */
.left-admin-panel-container::-webkit-scrollbar-thumb {
    background: rgb(60, 75, 100);
    border-radius: 2px;
    width: 5px;
}

@keyframes openMenu {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

@keyframes closeMenu {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(0);
    }
}

@media only screen and (max-width: 900px) {
    .left-admin-panel-menu {
        transform-origin: left;
        transform: scaleX(0);
    }

    .left-admin-panel-container {
        padding-left: 0px;
    }
}