.admin-home-main-container{
    display: flex;
    height: 100%;
    padding-top: 60px;
}
.admin-home-menu{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
}
.admin-home-menu-2{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
}
@media only screen and (max-width: 600px) {
    .admin-home-menu{
        display: none;
    }
    .open-menu{
        display: block;
    }
}
.admin-home-menu span{
    cursor: pointer;
    color: grey;
}
.admin-home-menu span:hover{
    color: black
}
.admin-home-menu-divider{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
}
.admin-home-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}