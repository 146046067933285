.footer-container{
    width: 100%;
    max-width: 1200px;
    background-color: rgb(84, 44, 85);
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    padding: 25px;
    flex-wrap: wrap-reverse;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
@media screen and (max-width: 991px) {
    .footer-container{
        margin: 0px auto;
    }
  }
.footer-map-container{
    width: 60%;
    display: flex;
    min-width: 300px;
    min-height: 250px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.footer-map-container img{
    width: 98%;
    min-width: 250px;
}
.footer-info-container{
    width: 40%;
    min-width: 300px;
    padding: 25px;
    color: white;
}
.preview-map{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview-map-image{
    width: 80%;
}