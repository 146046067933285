.admin-questions-main-container{
    display: flex;
    height: 100%;
    padding-top: 60px;

}
.admin-questions-menu{
    width: 300px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 10px;
}
.open-menu{
    width: 30px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 5px;
    padding-top: 20px;
    display: none;
}
.admin-questions-menu-2{
    width: 300px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 10px;
    position: fixed;
    z-index: 10000;
}
@media only screen and (max-width: 600px) {
    .admin-questions-menu{
        display: none;
    }
    .open-menu{
        display: block;
    }
}
.admin-questions-menu span{
    cursor: pointer;
    color: grey;
}
.admin-questions-menu span:hover{
    color: black
}
.admin-questions-menu-divider{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
}
.admin-questions-container{
    margin: 0 auto;
    overflow-x: scroll;
    width: 100%;
}
.admin-questions-main-container button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.admin-questions-main-container button:hover{
    background-color: black;
}
.admin-questions-main-container button:disabled{
    opacity: 0.8;
}
.admin-questions-main-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}

.message-container{
    width: 100%;
    height: 80px;
    background-color: white;
    border: 1px solid rgb(200,200,200);
    padding: 0px 5px;
    
}
.messahe-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.message-divider{
    height: 1px;
    background-color: rgb(200,200,200);
}
.message-container div{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}
.unread-message{
    max-width: 50px;
    height: 20px;
    background-color: aqua;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.show-message-container{
    width: 90%;
    max-width: 800px;
    margin: 10px auto;
    min-height: 300px;
}
.message-answer{
    width: 100%;
    height: 100px;
}