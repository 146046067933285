.home-contact-main-container{
    padding-top: 30px;
    background-color: black;
    text-align: center;
}
.home-contact-heading{
    color: white;
}
.contact-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    background-color: black;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
}
.contact-container-child{
    margin: 20px;
    width: 100%;
    max-width: 700px;
    min-width: 300px;
}
.contact-form{
    display: flex;
    flex-direction: column;
}
.contact-form-input{
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input-2{
    height: 100px;
    margin: 5px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input:focus{
    border: 1px solid rgb(56,152,236);
    border-radius: 0;
    outline: none;
}
.contact-form-input-2:focus{
    border: 1px solid rgb(56,152,236);
    border-radius: 0;
    outline: none;
}
.contact-form-input:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
.contact-form-input-2:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
@keyframes inputHover {
    from {transform: translateX(0px);}
    to {transform: translateX(10px);}
}
@keyframes inputHover2 {
    from {transform: translateX(10px);}
    to {transform: translateX(0px);}
}

.contact-map{
    width: 100%;
    height: 400px;
    background-color: red;
}
.contact-form-button{
    height: 40px;
    width: 150px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    margin: 5px auto;
}
.contact-form-button:hover{
    background-color: rgb(244,255,158);
    color: black;
}
.copyright{
    color: white;
}
@media screen and (max-width: 767px) {
    .contact-container{
        padding-left: 5px;
        padding-right: 5px;
    }
}