.home-accordion-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../../images/faq-min.jpeg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../images/faq-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    padding-bottom: 30px;
}
.home-accordion-heading{
    padding-top: 20px;
    font-size: 7vw;
    color: #f4ff9e;
}
.home-accordion-wrapper{
   background: transparent;
    max-width: 800px;
    padding: 20px;
}
.home-accordion-item{
    background:transparent;
}
.home-accordion-item-trigger{
    display: flex;
    justify-content: space-between;
    background: transparent;
}
.home-accordion-item-content{
    overflow: hidden;
    color: #f4ff9e;
    text-align: center;
}
.home-accordion-item-arrow-image {
    width: 30px;
    height: 30px;
    color: #f4ff9e;
}
.home-accordion-item-content-text{
    width: 80%;
    margin: 0 auto;
    text-align: left;
}
.home-accordion-item-heading{
    color: #f4ff9e !important;
    font-size: 20px;
}
@media screen and (max-width: 762px) {
    .home-accordion-heading{
       font-size: 11vw;
    }

}