.admin-products-main-container{
    display: flex;
    height: 100%;
    padding-top: 60px;

}
.admin-products-menu{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
}
.open-menu{
    width: 30px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 5px;
    padding-top: 20px;
    display: none;
}
.admin-products-menu-2{
    width: 250px;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/menu-background.png');
    //box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    border-right: 1px solid black;
    padding: 20px;
    position: fixed;
}
@media only screen and (max-width: 600px) {
    .admin-products-menu{
        display: none;
    }
    .open-menu{
        display: block;
    }
}
.admin-products-menu span{
    cursor: pointer;
    color: grey;
}
.admin-products-menu span:hover{
    color: black
}
.admin-products-menu-divider{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
}
.admin-products-container{
    margin: 0 auto;
    overflow-x: scroll;
    width: 100%;
}
.admin-products-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .admin-products-container::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .admin-products-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.admin-products-container table {
    margin: 0 auto;
}
.admin-products-container table tr {
    margin: 0 auto;
}
.admin-products-container table td{
    border: 1px solid black;
    max-width: 400px;
}

.admin-products-main-container button{

}
.admin-products-main-container button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.admin-products-main-container button:hover{
    background-color: black;
}
.admin-products-main-container button:disabled{
    opacity: 0.8;
}
.admin-products-main-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}