.product-card-container{
    background-color: white;
    margin: 20px;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
}
.product-card-images{
    display: flex;
    width: 100%;
}
.product-card-image{
    width: 100%;
    background-size: cover;
    background-position: 100% 50%;
    height: 200px;
}
.product-card-primary-text{
    color: rgb(88,88,88);
    font-weight: bold;
    font-size: 20px;
    padding: 10px;
}
.product-card-text{
    color: rgb(88,88,88);
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
}
.product-card-available-times{
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}
.product-card-available-time{
    display: flex;
    height: 40px;
    background-color: white;
    margin: 5px;
    color: rgb(88,88,88);
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid black;
    cursor: pointer;
    
}
.reserved{
    border: 1px solid rgb(56,152,236);
}
.product-card-container:hover{
    transform: scale(1.02);
}