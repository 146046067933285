body {
    background-color: #000;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #e4e4e4;
    font-size: 14px;
    line-height: 20px;
  }
  
  .hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    padding-top: 60px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 5px none #f4ff9e;
    border-bottom: 5px solid #f4ff9e;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.64)), to(rgba(0, 0, 0, 0.64))), url('../../images/Home/a4f41725-79a8-4646-99ca-a9f492e2eba3.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/Home/a4f41725-79a8-4646-99ca-a9f492e2eba3.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-attachment: scroll, fixed;
    text-align: center;
  }
  
  .theme {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #000;
    background-image: none;
    background-position: 0px 0px;
  }
  
  .promotional {
    display: block;
    padding-top: 69px;
    padding-bottom: 69px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-attachment: scroll, fixed;
  }
  
  .social-proof {
    margin-top: 58px;
  }
  
  .random-quote {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .faq {
    background-color: red;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 37px;
    padding-bottom: 37px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.78)), to(rgba(0, 0, 0, 0.78))), url('../../images/Home/goldrush4.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.78), rgba(0, 0, 0, 0.78)), url('../../images/Home/goldrush4.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    text-align: center;
  }
  
  .faq._1 {
    background-image: none;
    color: #f4ff9e;
    font-size: 79px;
    line-height: 104px;
  }
  
  .contact {
    margin-top: 49px;
  }
  
  .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .heading {
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png');
    background-position: 0px 0px;
    background-size: 125px;
  }
  
  .hero-title {
    margin-bottom: 0px;
    font-family: Oswald, sans-serif;
    color: #f4ff9e;
    font-size: 3vw;
    line-height: 7vw;
    font-weight: 300;
    text-align: left;
    letter-spacing: 32px;
    text-indent: 29px;
  }
  
  .text-span {
    font-weight: 600;
  }
  
  .hero-title-2 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: Tahoma, Verdana, Segoe, sans-serif;
    color: #fff;
    font-size: 5vw;
    line-height: 65px;
    font-weight: 700;
    text-align: left;
    text-transform: none;
  }
  
  .div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .paragraph {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    color: #f0f0f0;
    font-size: 21px;
    line-height: 49px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
  }
  
  .divider-yellow {
    display: block;
    width: 80%;
    height: 1px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f4ff9e;
  }
  
  .divider-yellow._1 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  
  .divider-yellow._3 {
    height: 1px;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  
  .hero-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .button {
    padding: 26px 33px;
    border-style: solid;
    border-width: 2px;
    border-color: #f4ff9e;
    border-radius: 8px;
    background-color: transparent;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #f4ff9e;
    font-size: 22px;
    font-weight: 700;
  }
  
  .button:hover {
    background-color: #f4ff9e;
    color: #444;
  }
  
  .navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 9999999999;
    border-bottom: 1px solid #f4ff9e;
    background-color: rgba(0, 0, 0, 0.68);
  }
  
  .nav-button {
    color: #f4ff9e;
  }
  
  .image {
    margin-top: 0px;
  }
  
  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    margin-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .brand {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .nav-logo {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 22px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #f4ff9e;
    font-size: 27px;
    font-weight: 500;
  }
  
  .text-span-2 {
    font-size: 26px;
    font-weight: 400;
  }
  
  .heading-3 {
    color: white;
    margin-top: 44px;
    line-height: 41px;
    text-align: left;
  }
  
  .flex-parent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    background-color: rgb(95,47,71);
  }
  .flex-parent-cards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    background-color: black;
  }

  .flex-child {
    width: 50%;
    padding: 20px 42px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 25px;
  }
  
  .flex-child._2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(36, 36, 36, 0.78);
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
  }
  
  .flex-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 350px;
    max-width: 500px;
    margin: 20px;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #181717;
  }
  
  .heading-4 {
    
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    color: #f4ff9e;
    text-align: center;
  }
  
  .section-intro {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .paragraph-2 {
    padding-top: 17px;
    padding-bottom: 17px;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    color: #c9c9c9;
    text-align: center;
  }
  
  .div-block-3 {
    background-color: black;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-5 {
    padding: 59px 83px;
    text-align: center;
  }
  
  .paragraph-3 {
    padding: 34px 82px;
    color: #f4ff9e;
    font-weight: 700;
    text-align: center;
  }
  
  .paragraph-3._1 {
    font-size: 1.7em;
    line-height: 3vw;
    font-weight: 400;
    text-align: justify;
  }
  
  .heading-6 {
    text-align: center;
  }
  
  .accordion-wrapper {
    width: 40vw;
    padding-top: 42px;
    padding-bottom: 42px;
    border-style: none;
    border-width: 1px;
    border-color: #f4ff9e;
  }
  .accordion-item{
      background:rgba(0, 0, 0, 0);
      border: none;
  }
  
  .accordion-item-trigger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 84%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #f4ff9e;
    cursor: pointer;
  }
  
  .accordion-item-content-close {
    overflow: hidden;
    color: #f4ff9e;
    text-align: center;
    animation: example;
    animation-duration: 2s;
    animation-direction: normal;
    transform-origin: 100% 0%;
  }
  .accordion-item-content {
    overflow: hidden;
    color: #f4ff9e;
    text-align: center;
  }
  
  .image-2 {
    width: 30px;
    color: #f4ff9e;
  }
  
  .text-block {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    color: #f4ff9e;
    text-align: justify;
  }
  
  .text-block-2 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
  
  .text-block-3 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
  
  .text-block-4 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
  
  .text-block-5 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
  
  .text-block-6 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
    height: auto;
  }
  
  .text-block-7 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
  }
  
  .heading-7 {
    color: #f4ff9e;
  }
  
  .form-block {
    width: 23%;
  }
  
  .div-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  
  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .div-block-6 {
    display: -ms-grid;
    display: grid;
    width: 78%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    justify-items: stretch;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ".";
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: center;
  }
  
  .text-field {
    height: 120px;
    border: 1px solid #fff;
    background-color: #000;
    color: #fff;
    line-height: 0px;
  }
  
  .text-field::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    text-decoration: none;
  }
  
  .text-field:-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    text-decoration: none;
  }
  
  .text-field::-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    text-decoration: none;
  }
  
  .text-field::placeholder {
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    text-decoration: none;
  }
  
  .form {
    color: #fff;
    text-align: center;
  }
  
  .text-field-2 {
    border: 1px solid #fff;
    background-color: #000;
    color: #a8a1a1;
  }
  
  .text-field-2:hover {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  
  .text-field-3 {
    border: 1px solid #fff;
    background-color: #000;
    color: #fff;
  }
  
  .text-field-4 {
    border: 1px solid #fff;
    background-color: #000;
    color: #fff;
  }
  
  .text-field-4:hover {
    background-color: #000;
  }
  
  .text-field-4:focus {
    background-color: transparent;
  }
  
  .text-field-5 {
    border: 1px solid #fff;
    background-color: #000;
    color: #fff;
  }
  
  .submit-button {
    padding: 10px 44px;
    border: 1px solid #fff;
    background-color: #000;
    font-size: 15px;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #f4ff9e;
    color: #302b2b;
  }
  
  .flip-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 450px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
  }
  
  .flip-card-wrapper {
    width: 100%;
    background-image: url('../../images/Home/goldrush4.jpg');
    background-position: 0px 0px;
    background-size: cover;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  
  .flip-card-wrapper.flip-bg {
    background-image: url('../../images/Home/goldrush4.jpg');
    background-position: 0px 0px;
    background-size: cover;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  
  .flip-card-wrapper.flip-2-bg {
    background-image: url('../../images/Home/79.jpg');
    background-position: 0px 0px;
    background-size: cover;
  }
  
  .flip-card-wrapper.flip-3-bg {
    background-image: url('../../images/Home/PAL_OB1-1.jpg');
    background-position: 0px 0px;
    background-size: cover;
  }
  
  .flip-card {
    position: relative;
    width: 100%;
    height: 450px;
  }
  
  .flip-card-front {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #000;
    background-color: rgba(84, 44, 85, 0.91);
    opacity: 1;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -o-object-fit: fill;
    object-fit: fill;
  }
  
  .flip-card-front._0 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .image-3 {
    border: 1px solid transparent;
  }
  
  .image-4 {
    background-color: transparent;
  }
  
  .image-5 {
    background-color: #c9b3b3;
  }
  
  /*.slide {
    max-height: 500px;
  }
  
  /*.slide-2 {
    margin-top: 0px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.46)), to(rgba(0, 0, 0, 0.46))), url('../../images/Home/Enchambered_54_990x660-1.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.46)), url('../../images/Home/Enchambered_54_990x660-1.jpg');
    background-position: 0px 0px, 50% 54%;
    background-size: auto, cover;
  }
  
  .slider {
    display: block;
    height: 500px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .slide-3 {
    background-image: url('../../images/Home/DSC_3365.jpg');
    background-position: 50% 61%;
    background-size: cover;
  }
  
  .slide-4 {
    background-image: url('../../images/Home/Enchambered_54_990x660-1.jpg');
    background-position: 50% 50%;
    background-size: cover;
  }*/
  
  .heading-8 {
    font-weight: 400;
  }
  
  .bold-text {
    font-weight: 400;
  }
  
  .heading-9 {
    margin: 29px -5px 29px 0px;
    text-align: center;
  }
  
  .heading-9.footer-text {
    width: 79%;
    margin-top: 34px;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
  }
  
  .footer-navi {
    padding-right: 10px;
    padding-left: 10px;
    font-family: Inconsolata, monospace;
    color: #757575;
    font-weight: 400;
    text-decoration: none;
  }
  
  .div-block-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .paragraph-4 {
    text-align: center;
  }
  
  .heading-10 {
    text-align: center;
  }
  
  .paragraph-5 {
    width: 70%;
    margin-top: 52px;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
  }
  
  .div-block-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-11 {
    text-align: center;
  }
  
  .italic-text {
    background-color: #000;
  }
  
  .heading-12 {
    background-color: #000;
  }
  
  .text-1 {
    font-size: 2vw;
    line-height: 27px;
  }
  
  .div-block-59 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 23px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .social-icon {
    display: block;
    margin-right: 21px;
    margin-left: 0px;
    padding-top: 11px;
    padding-bottom: 11px;
    opacity: 0.4;
  }
  
  .social-icon:hover {
    opacity: 1;
  }
  
  .paragraph-6 {
    text-align: left;
  }
  
  .bold-text-2 {
    margin-top: 81px;
  }
  
  .paragraph-7 {
    font-size: 19px;
  }
  
  .text-block-8 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
  }
  
  .paragraph-8 {
    width: 80%;
    padding-top: 25px;
  }
  
  .paragraph-9 {
    width: 80%;
  }
  
  .paragraph-10 {
    width: 80%;
  }
  
  .image-8 {
    -webkit-transform: rotateX(3deg) rotateY(0deg) rotateZ(180deg);
    transform: rotateX(3deg) rotateY(0deg) rotateZ(180deg);
    color: #141309;
    text-align: right;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .image-8:hover {
    -webkit-transform: rotateX(3deg) rotateY(0deg) rotateZ(180deg);
    transform: rotateX(3deg) rotateY(0deg) rotateZ(180deg);
  }
  
  .div-block-60 {
    text-align: left;
  }
  
  .link-block {
    text-align: left;
  }
  
  .section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 93px;
    margin-left: 93px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  
  .columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  
  .div-block-63 {
    width: 100%;
  }
  
  .div-block-64 {
    width: 100%;
    margin-right: 37px;
    margin-left: 37px;
  }
  
  .form-block-3 {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .map-2 {
    width: 100%;
    font-weight: 400;
  }
  
  @media screen and (max-width: 991px) {
    body {
      color: #cfcfcf;
    }
    .faq._1 {
      padding-top: 0px;
      font-size: 50px;
    }
    .heading-2 {
      font-size: 30px;
    }
    .heading-3 {
      margin-top: 0px;
      font-size: 18px;
      line-height: 41px;
      text-align: left;
    }
    .flex-child {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 17px;
    }
    .flex-child._2 {
      width: 70%;
      height: 55vh;
      margin-left: 33px;
      padding-right: 25px;
      padding-left: 25px;
    }
    .div-block-3 {
      padding-top: 0px;
      padding-right: 56px;
      padding-left: 56px;
    }
    .heading-5 {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 40px 0px 58px;
      font-size: 22px;
    }
    .paragraph-3._1 {
      padding: 0px;
      font-size: 1.6em;
      line-height: 5vw;
    }
    .accordion-wrapper {
      width: 65vw;
    }
    .accordion-item-trigger {
      width: 100%;
    }
    .accordion-item-content {
      width: 60%;
      margin-right: auto;
      margin-left: auto;
    }
    .text-block {
      width: auto;
    }
    .text-block-2 {
      width: auto;
    }
    .text-block-3 {
      width: auto;
    }
    .text-block-4 {
      width: auto;
    }
    .text-block-5 {
      width: auto;
    }
    .text-block-6 {
      width: auto;
    }
    .text-block-7 {
      width: auto;
    }
    .heading-9 {
      margin-top: 39px;
      margin-bottom: 39px;
      font-size: 30px;
    }
    .heading-11 {
      display: block;
      margin: 85px 104px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 14px;
    }
    .paragraph-7 {
      font-size: 16px;
    }
    .menu-button {
      background-color: #000;
      color: #000;
    }
    .menu-button.hover {
      background-color: #000;
    }
    .icon-3 {
      color: #fff;
    }
    .nav-menu-2 {
      background-color: #000;
      color: #000;
    }
    .section {
      margin-right: 25px;
      margin-left: 25px;
    }
    .div-block-64 {
      margin-right: 14px;
      margin-left: 14px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .hero {
      height: 95vh;
    }
    .random-quote {
      padding-right: 0px;
      padding-left: 0px;
    }
    .faq._1 {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 58px;
    }
    .heading-2 {
      font-size: 21px;
    }
    .hero-title-2 {
      margin-bottom: -14px;
      font-size: 10vw;
    }
    .divider-yellow {
      margin-top: -36px;
    }
    .button {
      padding: 21px;
      font-size: 17px;
      line-height: 14px;
    }
    .heading-3 {
      margin-bottom: 40px;
      padding-right: 16px;
      padding-bottom: 0px;
      padding-left: 16px;
      font-size: 20px;
      text-align: left;
    }
    .flex-parent {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .flex-child {
      width: 100%;
      height: 48vh;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 15px;
    }
    .flex-child._2 {
      width: 100%;
      margin-left: 0px;
    }
    .div-block-3 {
      padding-top: 2px;
      padding-right: 85px;
      padding-left: 85px;
    }
    .heading-5 {
      width: 455px;
      margin-top: 50px;
      margin-bottom: 0px;
      padding: 0px;
      font-size: 29px;
      line-height: 35px;
    }
    .paragraph-3 {
      padding-right: 0px;
      padding-left: 0px;
    }
    .paragraph-3._1 {
      padding: 37px 0px;
      font-size: 1.3em;
      line-height: 6vw;
    }
    .accordion-wrapper {
      width: 80vw;
    }
    .accordion-item-trigger {
      width: 100%;
    }
    .div-block-4 {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .flip-section {
      height: auto;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .flip-card-wrapper.flip-bg {
      display: block;
      height: 370px;
      -o-object-fit: fill;
      object-fit: fill;
    }
    .flip-card {
      height: 370px;
      max-width: none;
    }
    .flip-card-front {
      height: 370px;
    }
    .heading-8 {
      font-size: 20px;
    }
    .heading-9 {
      margin-top: 6px;
      font-size: 25px;
    }
    .heading-9.footer-text {
      margin-bottom: 0px;
    }
    .paragraph-5 {
      width: 88%;
      font-size: 16px;
    }
    .div-block-8 {
      margin: 33% 76px;
      padding-left: 0px;
    }
    .heading-11 {
      margin-top: 11px;
      margin-bottom: 11px;
      font-size: 16px;
    }
    .paragraph-7 {
      text-align: left;
    }
    .text-block-8 {
      width: auto;
    }
    .image-6 {
      width: 100px;
      height: 100px;
    }
    .image-7 {
      width: 100px;
      height: 100px;
    }
    .map {
      width: 85%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
      padding-left: 0px;
    }
    .div-block-61 {
      width: auto;
    }
    .div-block-62 {
      width: 90%;
      margin-top: 55px;
      margin-right: auto;
      margin-left: auto;
    }
    .section {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .div-block-64 {
      margin-top: 48px;
    }
  }
  
  @media screen and (max-width: 479px) {
    .hero {
      height: 73vh;
    }
    .theme {
      margin-right: 0px;
      margin-left: 0px;
    }
    .random-quote {
      margin-top: 70px;
      margin-bottom: 70px;
    }
    .faq._1 {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 47px;
      line-height: 82px;
    }
    .heading-2 {
      font-size: 12px;
      letter-spacing: 13px;
    }
    .hero-title-2 {
      font-size: 8vw;
    }
    .paragraph {
      margin-bottom: 0px;
      line-height: 0px;
    }
    .divider-yellow {
      margin-top: -12px;
    }
    .hero-wrapper {
      width: 100%;
      line-height: 0px;
    }
    .button {
      font-size: 11px;
      line-height: 10px;
    }
    .div-block-2 {
      width: 90%;
    }
    .nav-logo {
      font-size: 23px;
    }
    .heading-3 {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      color: #cfcfcf;
      font-size: 12px;
      line-height: 31px;
      text-align: center;
    }
    .heading-3.heading-text {
      margin-bottom: 20px;
      font-size: 14px;
      text-align: left;
    }
    .flex-parent {
      padding-right: 6px;
      padding-left: 6px;
    }
    .flex-child {
      height: auto;
      margin-bottom: 0px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      line-height: 25px;
      text-align: left;
    }
    .flex-child._2 {
      height: auto;
      margin-top: 30px;
      padding-top: 30px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .div-block-3 {
      padding-right: 24px;
      padding-left: 24px;
      font-size: 13px;
    }
    .heading-5 {
      width: 100vw;
      margin-top: 29px;
      margin-right: 40px;
      margin-left: 40px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 15px;
    }
    .paragraph-3._1 {
      padding-top: 25px;
      font-size: 13px;
      line-height: 9vw;
      text-align: justify;
    }
    .accordion-item-trigger {
      width: 90%;
    }
    .accordion-item-content {
      width: 90%;
    }
    .heading-7 {
      font-size: 16px;
    }
    .div-block-4 {
      width: auto;
    }
    .div-block-6 {
      width: 93%;
    }
    .flip-card-back {
      padding-top: 30px;
    }
    .slide-2 {
      background-color: #000;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.46)), to(rgba(0, 0, 0, 0.46))), url('../../images/Home/Enchambered_54_990x660-1.jpg');
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.46)), url('../../images/Home/Enchambered_54_990x660-1.jpg');
      background-position: 0px 0px, 50% 50%;
      background-size: auto, cover;
    }
    .heading-8 {
      overflow: visible;
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      -o-object-fit: fill;
      object-fit: fill;
    }
    .heading-9 {
      font-size: 20px;
    }
    .heading-10 {
      font-size: 31px;
    }
    .paragraph-5 {
      font-size: 12px;
    }
    .div-block-8 {
      margin-right: 0px;
      margin-left: 0px;
    }
    .heading-11 {
      width: 64%;
      margin-top: 59px;
    }
    .paragraph-6 {
      margin-top: 20px;
      font-size: 14px;
      line-height: 25px;
      text-align: justify;
    }
    .bold-text-2 {
      margin-top: 40px;
      font-size: 11px;
    }
    .paragraph-7 {
      width: 100%;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 26px;
      text-align: justify;
    }
    .text-block-8 {
      font-size: 13px;
    }
    .heading-13 {
      margin-top: 0px;
      font-size: 16px;
    }
    .heading-14 {
      font-size: 16px;
    }
    .heading-15 {
      padding-left: 0px;
      font-size: 16px;
      text-align: left;
    }
    .heading-16 {
      font-size: 16px;
    }
    .heading-17 {
      font-size: 16px;
    }
    .form-block-2 {
      padding: 10px 0px 0px;
    }
    .italic-text-2 {
      font-size: 12px;
    }
    .icon {
      font-size: 23px;
    }
    .icon-2 {
      font-size: 23px;
    }
    .map {
      width: 100%;
      padding-left: 0px;
    }
    .nav-menu {
      background-color: #000;
    }
    .menu-button {
      color: #000;
    }
    .icon-3 {
      color: #fff;
    }
    .nav-menu-2 {
      color: #000;
    }
    .section {
      margin-right: 10px;
      margin-left: 10px;
    }
    .div-block-65 {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
.theme-1{
    display: flex;
}
  
.contact-container{
    display: flex;
    flex-wrap: wrap-reverse;
    background-color: black;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
}
.contact-container-child{
    margin: 20px;
    width: 45%;
    min-width: 446px;
    background-color: black;
}
.contact-form{
    display: flex;
    flex-direction: column;
}
.contact-form-input{
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input-2{
    height: 100px;
    margin: 5px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
}
.contact-form-input:focus{
    border: 1px solid rgb(56,152,236);
    border-radius: 0;
    outline: none;
}
.contact-form-input-2:focus{
    border: 1px solid rgb(56,152,236);
    border-radius: 0;
    outline: none;
}
.contact-form-input:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
.contact-form-input-2:hover{
    animation: inputHover forwards;
    animation-duration: 0.5s;
}
@keyframes inputHover {
    from {transform: translateX(0px);}
    to {transform: translateX(10px);}
  }
  @keyframes inputHover2 {
    from {transform: translateX(10px);}
    to {transform: translateX(0px);}
  }

.contact-map{
    width: 100%;
    height: 400px;
    background-color: red;
}
.contact-form-button{
    height: 40px;
    width: 150px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    margin: 5px auto;
}
.contact-form-button:hover{
    background-color: rgb(244,255,158);
    color: black;
}



.test3{
  width: 400px;
  max-width: 400px;
  height: 400px;
  margin: 0 auto;
}
.test{
  min-width: 400px;
  height: 400px;
  background-color: #444;
  transform-origin: left;
}
.test2{
  position: relative;
  top: -400px;
  height: 400px;
  background-color: red;
  transform: scaleX(0);
  transform-origin: right;
}
.test-hover{
  animation: example forwards;
  animation-duration: 0.3s;
}
.test-hover2{
  animation: example2 forwards;
  animation-duration: 0.3s;
}

.test-leave{
  animation: example2 forwards;
  animation-duration: 0.3s;
}
.test-leave2{
  animation: example forwards;
  animation-duration: 0.3s;
}

@keyframes example {
  from {transform: scale(1,1);}
  to {transform: scale(0,1)}
}
@keyframes example2 {
  from {transform: scale(0,1);}
  to {transform: scale(1,1)}
}


.home-footer-container{
  width: 100%;
  padding: 50px;

  background-color: rgb(84, 44, 85);
}

.home-modal{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 100000;
  background-color: rgba(0, 0, 0, .21);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-modal-content{
  width: 95%;
  max-width: 600px;
  background-image: url('../../images/promocode.png');
  background-position: 0px 0px, 50% 50%;
  background-size: 100% 100%;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  justify-content: center;
  font-size: 2vh;
  padding-bottom: 80px;
}
.home-modal-content-div-x{
  font-size: 25px;
  margin-right: 15px;
  color: white;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.home-modal-content-div-1{
  width: 60%;
  margin: 30px;
  padding-bottom: 20px;
  height: auto !important;
  color: red !important;
  font-size: 12vh;
  border-bottom: 3px solid red;
}
.home-modal-content-div-2{
  width: 60%;
  height: auto !important;
  color: black !important;
  font-size: 3vh;
  border-bottom: 3px solid red;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.slide-container{
  background-color: red;
}
.each-slide-home{
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgb(49,38,44);
}
.each-slide-home div{
  min-height: 600px;
  display: flex;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}

.each-slide-home img{
  min-height: 300px;
  
}
.slide1{
  min-height: 500px;
  background: url('../../images/Slides/slide1-min.jpeg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: 50% 50%;
}
.slide2{
  min-height: 500px;
  background: url('../../images/Slides/slide2-min.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}
.slide3{
  min-height: 500px;
  background: url('../../images/Slides/slide3-min.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}
.slide{
  width: 100%;
  background-color: #000;
}
.slide h3{
  color: white !important
}
.slide-text{
  background-color: rgba(0, 0, 0, .3);
  padding: 20px;
  backdrop-filter: blur(2px);
}



