.admin-profile-container{
    padding-top: 60px;
    width: 100%;
    height: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.admin-profile-image{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 30px;
    
}
.admin-profile-container button{
    height: 40px;
    width: 150px;
    border: 1px solid black;
    color: white;
    background-color: rgb(84, 44, 85);
    margin: 5px;
    font-weight: bold;
}
.admin-profile-container button:hover{
    background-color: black;
}
.admin-profile-container button:disabled{
    opacity: 0.8;
}
.admin-profile-container button:disabled:hover{
    opacity: 0.8;
    background-color: rgb(84, 44, 85);
}