.admin-login-main-container{
    display: flex;
    background-color: black;
    height: 100%;
}
.admin-login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    background-color: black;
    margin: auto auto;
    height: 400px;
    padding: 30px;
}
.admin-login-container input{
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 10px;
    border: 1px solid white;
    color: white;
    background-color: black;
    animation: inputHover2 forwards;
    animation-duration: 0.5s;
    outline: none;
}
.admin-login-container input:focus{
    border: 1px solid rgb(56,152,236);
    border-radius: 0;
    outline: none;
}
.admin-login-container button{
    height: 40px;
    width: 150px;
    border: 1px solid white;
    color: black;
    background-color: white;
    margin: 5px;
    font-weight: bold;
}
.admin-login-container button:hover{
    background-color: rgb(84, 44, 85);
    color: white;
}
.admin-login-container img{
    width: 30%;
}
.login-logo-text{
    margin-top: 5px;
    margin-bottom: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: rgb(244, 255, 158);
    font-size: 30px;
    font-weight: 500;
}