.admin-navbar-container{
    display: flex;
    height: 60px;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    position: fixed;
    padding-left: 30px;
    width: 100%;
    border-bottom: 1px solid rgb(235,237,239);
}
.admin-navbar-option{
    margin-left: 20px;
    text-decoration: none;
    font-size: 20px;
}
.admin-navbar-option:hover{
    color: #f4ff9e;
}
.admin-navbar-account-container{
    width: 200px;
    height: 60px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.admin-navbar-account-container img{
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
}
.admin-navbar-account-container-img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: 20px;
}
.admin-navbar-account-container span{
    color: rgb(180,184,190);
}



.admin-navbar-account-menu{
    width: 200px;
    background-color: rgb(60, 75, 100);
    color: rgb(200, 200, 200);
    border: 1px solid black;
    position: fixed;
    z-index: 20000;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    padding-bottom: 20px;
}
.admin-navbar-account-option{
    cursor: pointer;
    color: rgb(200, 200, 200);
    text-decoration: none;
    margin: 5px;
}
.admin-navbar-account-option:hover{
    color: white;
}

.admin-menu-button{
    background-color: black;
    margin-left: 10px;
    color: white;
    font-size: 25px;
}
.admin-menu-options{
    position: fixed;
    width: 100%;
    background-color: black;
    z-index: 10000;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}