.prices-main-container{
    padding-top: 300px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url('../../images/reservation-min.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: cover;
    background-attachment: scroll, fixed;
    padding-bottom: 50px;
    
}
.prices-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../../images/12345.png');
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.prices-card{
    width: 90%;
    max-width: 300px;
    background-color: white;
    box-shadow: 7px 7px 9px 5px rgba(0, 0, 0, 0.21);
    margin: 10px;
    color: rgb(88,88,88)
}
.prices-card-from{
    color: rgb(88,88,88);
    text-align: center;
    padding: 10px;
}
.prices-card-header{
    display: flex;
    align-items: center;
    background-color: rgb(84, 44, 85);
    color: white;
}
.prices-card-header img{
    filter: drop-shadow(2px 2px 4px white);
    height: 70px;
}
.prices-card-header h4{
    color: white !important; 
}
.prices-card-content{
    display: flex;
    align-items: center;
}
.prices-card-content div{
    width: 100%;
    text-align: center;
}
@media screen and (max-width: 991px) {
    .prices-main-container{
        padding-top: 60px;
        padding-bottom: 0px;
    }
}